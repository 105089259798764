export const theamJs = () => {
  // const scriptElement = document.querySelector(
  //   "script[src='/new_assets/js/theme.js?v=1.0']"
  // );
  // if (scriptElement) {
  //   scriptElement.remove();
  // }
  const script = document.createElement("script");
  script.src = "/new_assets/js/theme.js?v=1.0"; // path of the plugin called Slick Slider
  script.async = true; // false not work too
  document.body.appendChild(script); // the element <script> is appended but no work
};

export const removeThemeJs = () => {
  const scriptElement = document.querySelector(
    "script[src='/new_assets/js/theme.js?v=1.0']"
  );
  if (scriptElement) {
    scriptElement.remove();
  }
}

export const newtheamJs = () => {
  // const scriptElement = document.querySelector(
  //   "script[src='/new_assets/js/newtheam.js?v=1.0']"
  // );
  // if (scriptElement) {
  //   return;
  // }
  const script = document.createElement("script");
  // script.src = "/new_assets/js/newtheam.js?v=1.0" // path of the plugin called Slick Slider
  script.async = true; // false not work too
  document.body.append(script); // the element <script> is appended but no work
};

export const tooltipFun = () => {
  const scriptElement = document.querySelector(
    "script[src='/new_assets/js/tooltip.js']"
  );
  if (scriptElement) {
    scriptElement.remove();
  }
  const script1 = document.createElement("script");
  script1.src = "/new_assets/js/tooltip.js";
  script1.async = true;
  document.body.append(script1);
};

// export const themeChiled = () => {
//   const scriptElement = document.querySelector(
//     "script[src='/new_assets/js/themeChiled.js']"
//   );
//   if (scriptElement) {
//     scriptElement.remove();
//   }
//   const script1 = document.createElement("script");
//   script1.src = "/new_assets/js/themeChiled.js";
//   script1.async = true;
//   document.body.append(script1);
// };
