"use client";
import { theamJs, removeThemeJs } from "@/utility";
import { useEffect } from "react";
import { usePathname } from "next/navigation";

const template = ({ children }) => {
  const pathname = usePathname();
  useEffect(() => {
    const scriptElement = setTimeout(function () {
     window.onload = theamJs();
    }, 1800);
    return () => {
      removeThemeJs();
      clearTimeout(scriptElement);
    };
  }, [pathname]);
  return <>{children}</>;
};

export default template;
